import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import {
  Typography as SunbeamTypography,
  Link as SunbeamLink,
  Divider as SunbeamDivider,
  Table as SunbeamTable,
  TableContainer as SunbeamTableContainer,
  TableRow as SunbeamTableRow,
  TableCell as SunbeamTableCell,
  TableHead as SunbeamTableHead,
  TableBody as SunbeamTableBody,
  Paper as SunbeamPaper,
} from '@achieve/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { MediaImage, SuperScript } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { get as _get, cloneDeep as _cloneDeep } from 'lodash-es'
import { BlockQuote } from 'components/BlockQuote'
import { CardCTA } from 'components/Cards/CardCTA'
import styles from './TypographyLongForm.module.scss'
import { isExternal } from 'utils/conversions'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { VideoPlayer } from 'components/VideoPlayer'

/**
 * Contentful RichText places empty paragraph after elements
 * like headings and lists if they are last item in the RichText box
 * @param {Array} content
 * @returns {Array} filtered content
 */
const removeTrailingReturnChar = (content) => {
  if (!Array.isArray(content)) {
    // Gracefully fail
    return content
  }
  if (content.length <= 1) {
    return content
  }
  const lastItem = content[content.length - 1]
  if (
    typeof lastItem.nodeType === 'string' &&
    lastItem.nodeType.match(/paragraph/i) &&
    lastItem.content.length === 1 &&
    lastItem.content[0].nodeType === 'text' &&
    lastItem.content[0].value === ''
  ) {
    return content.slice(0, -1)
  }
  return content
}

/**
 * Always returns textContent with content array as a prop in the shape
 * @param {Object} content
 * @returns {Object} textContent: {content: []}
 */
const handleContentToMapper = (content = {}) => {
  let textContent = {}
  if (Object.hasOwnProperty.call(content, 'fields')) {
    textContent = content.fields.textContent
  } else if (Object.hasOwnProperty.call(content, 'nodeType')) {
    textContent = content
  }
  textContent.content = removeTrailingReturnChar(textContent.content)

  return { textContent }
}
const RICH_TEXT = {
  [BLOCKS.PARAGRAPH]: { variant: 'bodyS30', component: 'p' },
  [BLOCKS.HEADING_1]: { variant: 'displayM10', component: 'h1' },
  [BLOCKS.HEADING_2]: {
    variant: 'displayS20',
    component: 'h2',
    fontWeight: 'medium',
  },
  [BLOCKS.HEADING_3]: { variant: 'displayS10', component: 'h3', fontWeight: 'medium' },
  [BLOCKS.HEADING_4]: { variant: 'displayXS30', component: 'h4', fontWeight: 'medium' },
  [BLOCKS.HEADING_5]: { variant: 'displayXS20', component: 'h5' },
  [BLOCKS.HEADING_6]: { variant: 'displayXS10', component: 'h6' },
  [BLOCKS.OL_LIST]: { component: 'ol' },
  [BLOCKS.UL_LIST]: { component: 'ul' },
  [BLOCKS.LIST_ITEM]: { component: 'li' }, //sx properties apply to single child element
  [BLOCKS.HR]: {},
  [BLOCKS.EMBEDDED_ASSET]: {},
  [BLOCKS.TABLE]: { variant: 'bodyS40', component: 'table' },
  [BLOCKS.TABLE_ROW]: { variant: 'bodyS40', component: 'tr' },
  [BLOCKS.TABLE_CELL]: { variant: 'bodyS40', component: 'td' },
  [BLOCKS.TABLE_HEADER_CELL]: { variant: 'bodyS40', component: 'th' },
  [INLINES.HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  [INLINES.ASSET_HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  // Currently unimplemented in Sunbeam
  //[MARKS.CODE]: {component: 'code'},
  //[BLOCKS.QUOTE]:{},
}

/**
 * Take an object of overrides and if the enum is passed in the object then return the override.
 * @param {*} richTextEnum @contentful/rich-text-types enum
 * @param {Object} variantOverride object of rich-text-types as keys and Sunbeam Variants as the value
 * @returns {String} the default or override variant
 */
const selectOverride = (richTextEnum, overrideObject, key) => {
  return typeof overrideObject === 'object' &&
    Object.hasOwnProperty.call(overrideObject, richTextEnum)
    ? overrideObject[richTextEnum]
    : RICH_TEXT[richTextEnum][key]
}

const parseRichTextLineBreak = (children) => {
  if (Array.isArray(children) && children.length === 1 && children[0] === '') {
    return ['\u00A0']
  }
  // Remove line separator characters from Contentful RichText
  return children.map((child) =>
    typeof child === 'string' ? child.replaceAll(/[\u2028\u2029]/g, '') : child
  )
}

/**
 * Render Contentful TypographyLongForm components from Contentful Rich Text
 * To build the variantOverride object you will need to import import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
 * @param {Object} content
 * @param {Object} variantOverride object of rich-text-types as keys and Sunbeam Variants as the value
 * @param {Object} styleOverride object of rich-text-types as keys and a MUI "sx" object as the value
 * @returns
 */
function TypographyLongForm({
  content: typographyContent = '',
  variantOverride,
  styleOverride,
  ...props
}) {
  if (typeof typographyContent === 'string') {
    return (
      <SunbeamTypography
        sx={selectOverride(BLOCKS.PARAGRAPH, styleOverride, 'sx')}
        variant={selectOverride(BLOCKS.PARAGRAPH, variantOverride, 'variant')}
        component={RICH_TEXT[BLOCKS.PARAGRAPH].component}
        className={styles['typography-paragraph']}
        {...props}
      >
        {typographyContent}
      </SunbeamTypography>
    )
  }
  // otherwise must be an object
  const { textContent } = handleContentToMapper(typographyContent)

  /**
   * Create renderNode options for BLOCKS that return similar/identical elements (headers and lists)
   * @param {Object} node      //Is provided by documentToReactComponents
   * @param {Object} children  //Is provided by documentToReactComponents
   * @param {String} blockType      //BLOCKTYPE allowed by documentToReactComponents
   * @param {Boolean} variantToChild //true if variant override should be applied to child component
   * @param {Boolean} styleToChild   //true if style override should be applied to child component
   * @returns {JSX} <JSX element to be displayed >
   */
  function createRenderNode(
    node,
    children,
    blockType,
    className = 'typography-default',
    variantToChild = false,
    styleToChild = false
  ) {
    let childWithParentProps = children
    if (variantToChild || styleToChild) {
      childWithParentProps = _cloneDeep(children)
      if (variantToChild) {
        //Pass block variant to child
        childWithParentProps.forEach((child) => {
          if (Object.hasOwnProperty.call(child, 'props'))
            child.props.variant = selectOverride(BLOCKS[blockType], variantOverride, 'variant')
        })
      }
      if (styleToChild) {
        //Pass block style to child
        childWithParentProps.forEach((child) => {
          if (Object.hasOwnProperty.call(child, 'props'))
            child.props.sx = selectOverride(BLOCKS[blockType], styleOverride, 'sx')
        })
      }
    }

    const dataTestId = _get(node, 'content[0].value')
      ? `${_get(node, 'content[0].value')}`.slice(0, 50)
      : 'no-id-needed'
    const dataIdReference = _get(props, 'id')
      ? _get(props, 'id')
      : `${BLOCKS[blockType]}-${(_get(node, 'content[0].value') ?? '')
          .slice(0, 50)
          .replaceAll(' ', '-')}`
    const classNames = [className, props.className].join(' ')
    return (
      <SunbeamTypography
        sx={selectOverride(BLOCKS[blockType], styleOverride, 'sx')}
        variant={selectOverride(BLOCKS[blockType], variantOverride, 'variant')}
        component={RICH_TEXT[BLOCKS[blockType]].component}
        fontWeight={RICH_TEXT[BLOCKS[blockType]].fontWeight}
        data-testid={dataTestId}
        id={dataIdReference}
        className={classNames}
        {...props}
      >
        {parseRichTextLineBreak(childWithParentProps)}
      </SunbeamTypography>
    )
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong {...props}>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em {...props}>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        createRenderNode(node, children, 'PARAGRAPH', styles['typography-paragraph']),
      [BLOCKS.HEADING_1]: (node, children) =>
        createRenderNode(node, children, 'HEADING_1', styles['typography-heading-1'], true),
      [BLOCKS.HEADING_2]: (node, children) =>
        createRenderNode(node, children, 'HEADING_2', styles['typography-heading-2'], true),
      [BLOCKS.HEADING_3]: (node, children) =>
        createRenderNode(node, children, 'HEADING_3', styles['typography-heading-3'], true),
      [BLOCKS.HEADING_4]: (node, children) =>
        createRenderNode(node, children, 'HEADING_4', styles['typography-heading-4'], true),
      [BLOCKS.HEADING_5]: (node, children) =>
        createRenderNode(node, children, 'HEADING_5', styles['typography-heading-5'], true),
      [BLOCKS.HEADING_6]: (node, children) =>
        createRenderNode(node, children, 'HEADING_6', styles['typography-heading-6'], true),
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className={styles['typography-ol']}>{children}</ol>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={styles['typography-ul']}>{children}</ul>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className={styles['typography-li']}>{children}</li>
      },
      [BLOCKS.HR]: () => {
        return (
          <SunbeamDivider
            className={styles['typography-hr']}
            sx={selectOverride(BLOCKS.HR, styleOverride, 'sx')}
            {...props}
          />
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageWidth = _get(node, 'data.target.fields.file.details.image.width')
        const imageHeight = _get(node, 'data.target.fields.file.details.image.height')

        return (
          <>
            <MediaImage
              content={node?.data.target}
              width={imageWidth}
              height={imageHeight}
              {...props}
            />
            <p></p>
          </>
        )
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div>
            <SunbeamTableContainer
              component={SunbeamPaper}
              className={`${styles['typography-table']} ${
                node?.content[0]?.content?.length < 4 && styles['typography-table-full']
              }`}
            >
              <SunbeamTable
                size="small"
                sx={selectOverride(BLOCKS.TABLE, styleOverride, 'sx')}
                variant={selectOverride(BLOCKS.TABLE, variantOverride, 'variant')}
                {...props}
              >
                {children}
              </SunbeamTable>
            </SunbeamTableContainer>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const childrenNodeTypes = node?.content?.map((c) => c?.nodeType) ?? []
        if (childrenNodeTypes.includes(BLOCKS.TABLE_HEADER_CELL)) {
          return (
            <SunbeamTableHead>
              <SunbeamTableRow>{children}</SunbeamTableRow>
            </SunbeamTableHead>
          )
        }
        return (
          <SunbeamTableBody>
            <SunbeamTableRow>{children}</SunbeamTableRow>
          </SunbeamTableBody>
        )
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <SunbeamTableCell className={styles['typography-cell']}>{children}</SunbeamTableCell>
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return <SunbeamTableCell className={styles['typography-cell']}>{children}</SunbeamTableCell>
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <BlockQuote text={node.content}>{children}</BlockQuote>
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return HYPERLINK(node, children, props, styleOverride, variantOverride)
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const linkId = node?.content[0]?.value
        const url = node?.data?.target?.fields?.file.url
        const fileLink = url.includes('http') ? url : `https:${url}`
        return (
          <SunbeamLink
            target="_blank"
            sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx')}
            variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant')}
            data-testid={`Link-${url}-${linkId}`}
            href={fileLink}
            aria-label={`${children} - link opens in a new tab`}
            {...props}
            component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            className={styles['typography-hyperlink']}
          >
            {children}
          </SunbeamLink>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child
        const { plainText, styledText, variation, identifier, videoPlayer, uiComponent } = _get(
          node,
          'data.target.fields',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (contentType?.sys?.id === 'articleCtaCard') {
          const { fields } = _get(node, 'data.target', {})
          return <CardCTA content={fields} />
        }
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <SunbeamLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx')}
              variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant')}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 72,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </SunbeamLink>
          )
        }
        if (videoPlayer) {
          const { target } = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }
        if (uiComponent === 'VideoPlayer') {
          const target = _get(node, 'data.target.fields', {})
          return (
            <VideoPlayer
              content={target}
              frameClassName={styles['video-player']}
              frameEmbed={true}
            />
          )
        }

        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child
        const { plainText, styledText, variation, identifier, videoPlayer, uiComponent } = _get(
          node,
          'data.target.fields',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (contentType?.sys?.id === 'articleCtaCard') {
          const { fields } = _get(node, 'data.target', {})
          return <CardCTA content={fields} />
        }
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <SunbeamLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx')}
              variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant')}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 72,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={RICH_TEXT[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </SunbeamLink>
          )
        }
        if (videoPlayer) {
          const { target } = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }
        if (uiComponent === 'VideoPlayer') {
          const target = _get(node, 'data.target.fields', {})
          return (
            <VideoPlayer
              content={target}
              frameClassName={styles['video-player']}
              frameEmbed={true}
            />
          )
        }

        return null
      },
    },
    renderText: (text) => text,
  }
  return documentToReactComponents(textContent, options)
}
function HYPERLINK(node, children, props, styleOverride, variantOverride) {
  const currentURL = useCurrentUrl()
  const linkId = node?.content[0].value
  const url = node?.data.uri
  return (
    <AchieveLink
      sx={selectOverride(INLINES.HYPERLINK, styleOverride, 'sx')}
      variant={selectOverride(INLINES.HYPERLINK, variantOverride, 'variant')}
      data-testid={`Link-${url}-${linkId}`}
      href={url}
      target={isExternal(url, currentURL) ? '_blank' : '_self'}
      {...props}
      component={RICH_TEXT[INLINES.HYPERLINK].component}
      className={styles['typography-hyperlink']}
    >
      {children[0] || children}
    </AchieveLink>
  )
}

export default TypographyLongForm
export { TypographyLongForm, selectOverride, removeTrailingReturnChar, RICH_TEXT }
