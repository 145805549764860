import { get as _get } from 'lodash-es'
import Footer from 'components/Footer'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import footerNavigation from 'components/Footer/footerNavigation.json'

export default function FooterContainer({
  pageDisclosure,
  disabledRoutes,
  content,
  secondaryDisclosures,
}) {
  let footerMenu = footerNavigation.footerMenus
  const logoImage = _get(content, 'logo.fields.file.url')
  const brandCopy = _get(content, 'logoHelpText')
  const menuContent = _get(content, 'footerItems')
  const usesCmsFooter = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_CMS_FOOTER)
  if (menuContent && usesCmsFooter) {
    footerMenu = []
    for (let i = 0; i < menuContent.length; i++) {
      const element = menuContent[i]
      const subMneu = []
      for (let j = 0; j < element?.fields?.gridItems.length; j++) {
        const subElement = element?.fields?.gridItems[j]
        subMneu.push({
          label: _get(subElement, 'fields.linkText.content[0].content[0].value'),
          url: _get(subElement, 'fields.linkHref'),
        })
      }
      footerMenu.push({
        menuLabel: _get(element, 'fields.name'),
        menuItems: subMneu,
      })
    }
  }
  return (
    <Footer
      footerNavigation={footerMenu}
      pageDisclosure={[pageDisclosure, ...secondaryDisclosures]}
      disabledRoutes={disabledRoutes}
      logoImage={logoImage}
      brandCopy={brandCopy}
    />
  )
}
