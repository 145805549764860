/**
 * Takes text and return sulugified version
 * @param {String} slug
 * @returns {String} formatted slug string
 */
function getSlug(slug = '') {
  return slug
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

function isExternal(url, currentURL) {
  try {
    const CURRENT_URL = new URL(currentURL)
    const URLObject = new URL(url)
    return URLObject.host != CURRENT_URL.host
  } catch (err) {
    return false
  }
}

export { getSlug, isExternal }
