import { useEffect, useState } from 'react'

const useBot = (defaultValue = true) => {
  const [isBot, setIsBot] = useState(defaultValue)

  useEffect(() => {
    const botRegexp = new RegExp('bot|googlebot|crawler|spider|robot|crawling|lighthouse', 'i')

    setIsBot(botRegexp.test(window.navigator.userAgent))
  }, [])

  return isBot
}

export default useBot
