export const ogIncludeMetaTypeArray = [
  'about',
  'home-equity-loan',
  'personal-loans',
  'resolve-debt',
  'learn',
  'consumer-name',
]

export const ogIncludeMetaTypeArticleArray = ['learn', 'consumer-name', 'blog']
