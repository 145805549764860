// NOTE: Dialog is being used from Sunbeam though the other icons and Dialog related components are
// coming directly from MUI. TODO - ask Sunbeam team to see if this can all come from them
import { Button, Typography, Dialog, IconButton } from '@achieve/sunbeam'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import { useState, useReducer, useEffect } from 'react'
import { VideoPlayer } from 'components/VideoPlayer'
import useViewPortSize from 'hooks/useViewportSize'
import { videoMap } from 'constants/videoMappings'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import styles from './VideoDialog.module.scss'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoDialog({ content }) {
  const { title, videoPlayer, altText, source } = useInlineMemoizedContentGetter(content, [
    'title',
    'videoPlayer',
    'altText',
    'source',
  ])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [open, setOpen] = useState(false)

  const { breakpointLetterSize, calculatedClient } = useViewPortSize()
  const [state, dispatch] = useReducer(reducerFunction, {
    width: videoMap[breakpointLetterSize].width,
  })

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          width: videoMap[breakpointLetterSize].width,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedClient, breakpointLetterSize])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        data-testid="video-dialog-button"
        data-video-dialog-open={true}
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<PlayCircleOutlineIcon data-testid="circle-icon" />}
      >
        <Typography variant="displayS10" fontWeight="bold">
          {title}
        </Typography>
      </Button>
      <Dialog
        data-testid="video-dialog"
        className={styles['dialog-container']}
        fullWidth={false}
        maxWidth={false}
        fullScreen={isMobile}
        open={open}
        PaperProps={{
          sx: {
            width: isMobile ? 'max-content' : state.width,
            height: 'max-content',
            backgroundColor: 'transparent',
          },
        }}
      >
        <DialogContent data-testid="dialog-content" className={styles['dialog-content']}>
          <DialogActions data-testid="dialog-action" style={{ padding: 0, margin: 0 }}>
            <IconButton
              data-testid="icon-button"
              className={styles['icon-button']}
              onClick={handleClose}
              data-video-dialog-close={true}
            >
              <CloseIcon data-video-dialog-close={true} />
            </IconButton>
          </DialogActions>
          <div className={styles['video-player-container']}>
            <VideoPlayer
              content={
                !source
                  ? { ...videoPlayer, autoplay: true, openFullScreen: true }
                  : { altText, source, autoplay: true, openFullScreen: true }
              }
              maxWidth="true"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { VideoDialog }
