import Container from '@mui/material/Container'
import { MediaImage } from 'components/Contentful'
import styles from './Section.module.scss'
import classNames from 'classnames'
import { Image } from 'components/Image'

const bgBlue = 'blue'
const bgDefault = 'bgDefault'
const bgShadowPrimaryHighlightBlue = 'bgShadowPrimaryHighlightBlue'
const bgShadowPrimaryHighlightWhiteBlue = 'bgShadowPrimaryHighlightWhiteBlue'
const blueGradient1 = 'blueGradient1'
const purpleBlueGradient1 = 'purpleBlueGradient1'
const neutralGrey8 = 'neutralGrey8'
const white = 'white'

export const SECTION_VARIANTS = {
  bgBlue,
  bgDefault,
  bgShadowPrimaryHighlightBlue,
  bgShadowPrimaryHighlightWhiteBlue,
  blueGradient1,
  purpleBlueGradient1,
  neutralGrey8,
  white,
}

function Section({
  children,
  backgroundColor,
  backgroundImageAlt = '',
  backgroundImageContent,
  backgroundPriority = false,
  backgroundObjectPosition = 'top',
  childContainerClassName,
  className,
  id,
  showPattern = false,
  contain = true,
  variant = bgDefault,
  ...containerProps
}) {
  const contents = contain ? (
    <Container
      maxWidth={false}
      disableGutters
      className={classNames(styles['achieve-section-container'], childContainerClassName)}
      data-narrow={true}
      {...containerProps}
    >
      {children}
    </Container>
  ) : (
    children
  )
  return (
    <section
      id={id}
      style={{ backgroundColor }}
      className={classNames(styles['achieve-section'], className)}
      data-variant={variant}
    >
      {showPattern && (
        <Image
          src="/a-pattern.svg"
          alt="Achieve pattern"
          layout="fill"
          objectFit="cover"
          objectPosition={backgroundObjectPosition}
          className={styles['achieve-pattern']}
        />
      )}
      {/* Only display the a full background image when present and background pattern is disabled */}
      {!showPattern && backgroundImageContent && (
        <span className={styles['fullbg-image']}>
          <MediaImage
            content={backgroundImageContent}
            layout="fill"
            objectFit="cover"
            alt={backgroundImageAlt}
            objectPosition={backgroundObjectPosition}
            priority={backgroundPriority}
            width
            height
          />
        </span>
      )}
      {contents}
    </section>
  )
}

export default Section
