import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { Typography } from '@achieve/sunbeam'
import { SIGN_IN_ROUTE } from 'constants/navigation'

function SignInLink({ disabledRoutes = [], ...restProps }) {
  if (disabledRoutes.includes(SIGN_IN_ROUTE)) {
    return null
  }

  return (
    <AchieveLink
      color="primary"
      href={SIGN_IN_ROUTE}
      track={{
        click_id: 'Sign in',
        click_type: 'Link Click',
        nav_link_section: 'Header',
        track_event: 'global_header',
      }}
      variant="bodyS30"
      {...restProps}
    >
      <Typography fontWeight="medium">Sign in</Typography>
    </AchieveLink>
  )
}

export { SignInLink }
