import { MediaImage, Typography, LinkButton } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import styles from './CardCTA.module.scss'

export const CardCTA = ({ content, variant }) => {
  let { link, title, subtitle, image } = content

  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)

  return (
    <div className={styles[`outer-wrapper-${variant ? variant : 'main'}`]}>
      <Grid className={styles['container']}>
        <Grid className={styles['inner-container']}>
          {image && (
            <div className={styles['image-container']}>
              <MediaImage
                className={styles['image']}
                alt={image.fields.mediaAltText}
                content={image.fields.mediaContent}
                data-testid="cta-card-main-image"
                objectFit="cover"
              />
            </div>
          )}
          <Grid className={styles['text-container']}>
            <Typography
              className={styles['cta-title']}
              fontWeight={variant ? 'medium' : 'bold'}
              variant={variant ? 'displayS20' : isMobile ? 'displayS20' : 'displayS30'}
              content={title}
            ></Typography>
            {subtitle && (
              <Typography
                className={styles['cta-subtitle']}
                variant={variant ? 'displayXS30' : 'displayS30'}
                content={subtitle}
              ></Typography>
            )}
            {variant ? (
              <LinkButton
                className={styles['cta-button']}
                content={link}
                variant={variant == 'widget' ? 'outlined' : 'primary'}
                color={variant == 'widget' ? 'primary' : 'secondary'}
                data-testid="cta-card-button"
              />
            ) : (
              <LinkButton
                className={styles['cta-button']}
                content={link}
                data-testid="cta-card-button"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
