import { GET_STARTED_ROUTE } from 'constants/navigation'

const getProductAndAddUtmToGetStarted = (memoHref) => {
  const isGetStarted =
    memoHref?.replace('/', '').substring(0, 11) === GET_STARTED_ROUTE.split('?')[0].replace('/', '')
  if (isGetStarted) {
    if (memoHref.indexOf('utm_medium') === -1) {
      memoHref = `${memoHref}&utm_medium=organic`
    }
    if (memoHref.indexOf('utm_media') === -1) {
      memoHref = `${memoHref}&utm_media=organic`
    }
    if (memoHref.indexOf('utm_source') === -1) {
      let product
      product = memoHref
        ?.split('?')[1]
        ?.split('&')
        ?.filter((qp) => qp.split('=')[0] == 'section')[0]
        .split('=')[1]
        .split('_')[1]
      memoHref = `${memoHref}&utm_source=achieve_organic_${product}`
    }
  }

  return memoHref
}

export { getProductAndAddUtmToGetStarted }
