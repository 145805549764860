// Next.js Edge Runtime safe base64 encoding
// https://nextjs.org/docs/api-reference/edge-runtime#encoding-apis
const encodeBase64 = (str) => {
  //return Buffer.from(str).toString('base64')
  return btoa(str)
}

const AUTHORIZATION_HEADER_TOKEN_POST = `Basic ${encodeBase64(
  `${process.env.FFN_AUTH_CLIENT_ID}:${process.env.FFN_AUTH_CLIENT_SECRET}`
)}`

const AUTHORIZATION_HEADER_TOKEN_POST_MAIL = `Basic ${encodeBase64(
  `${process.env.FFN_AUTH_EMAIL_CLIENT_ID}:${process.env.FFN_AUTH_EMAIL_CLIENT_SECRET}`
)}`

const token = {
  // String
  access_token: null,
  // String: Bearer
  token_type: null,
  // Number
  expires_in: null,
  // Date
  expires: null,
  // Object: { Authorization: "Bearer access_token" }
  authorization_header: null,
}

/**
 * Get a new access token
 * @returns {void}
 */
const refreshToken = async (mail, timeBeforeRefresh = 15) => {
  // Omit mail tokens from expiration check
  if (!mail && token.expires > Date.now()) {
    return
  }
  const res = await fetch(`${process.env.FFN_AUTH_URL}/v1/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: mail ? AUTHORIZATION_HEADER_TOKEN_POST_MAIL : AUTHORIZATION_HEADER_TOKEN_POST,
      'Cache-Control': 'no-cache',
    },
    body: 'grant_type=client_credentials',
  })
  const data = await res.json()

  console.info('refreshToken response: ', JSON.stringify(data))

  if (typeof data !== 'object') {
    throw new Error('Invalid refresh token response', data)
  }
  if (data.error) {
    console.error('refreshToken error', JSON.stringify(data))
    throw new Error(data.error)
  }
  token.access_token = data.access_token
  token.token_type = data.token_type
  token.expires_in = data.expires_in
  token.expires = Date.now() + (data.expires_in - timeBeforeRefresh) * 1000
  token.authorization_header = { Authorization: `${token.token_type} ${token.access_token}` }

  console.info('refreshToken new token: ', JSON.stringify(token))
}

const ffnAuthFetch = async (url, init) => {
  await refreshToken()
  init.headers = { ...init.headers, ...token.authorization_header }
  return fetch(url, init)
}

const ffnAuthFetchMail = async (url, init) => {
  await refreshToken(true)
  init.headers = { ...init.headers, ...token.authorization_header }

  console.info('ffnAuthFetchMail url: ', JSON.stringify(url))
  console.info('ffnAuthFetchMail init: ', JSON.stringify(init))
  return fetch(url, init)
}

const ffnAuth = { fetch: ffnAuthFetch, refreshToken, ...token }
const ffnMailAuth = { fetch: ffnAuthFetchMail, refreshToken, ...token }

export { ffnAuth, ffnMailAuth }
