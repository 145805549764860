const DEVICES = {
  desktop: 'desktop',
  ios: 'ios',
  android: 'android',
}

const TYPES_SOURCES_VIDEOS = {
  vimeo: 'vimeo',
  youtube: 'youtube',
}

/**
 * Scrolls the window to the top of the page with a smooth animation effect.
 * @returns {void}
 */
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

/**
 * Detects whether the current browser is running on an iOS Safari mobile device.
 * @param {string} userAgent - The user agent string of the browser.
 * @returns {boolean} Returns true if the browser is running on an iOS Safari mobile device,
 * otherwise false.
 */
const iOSSafariMobile = (userAgent) => {
  return (
    (/iP(ad|od|hone)/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

/**
 * Returns true if the current user agent is an iOS device, or if it's running on an iPad with iOS 13 or later.
 * @returns {boolean} True if the current user agent is an iOS device or iPad on iOS 13 or later, false otherwise.
 */
function iOS() {
  return (
    /iP(ad|od|hone)/i.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

/**
 * Determines the current device by inspecting the user agent string, and returns the appropriate device type.
 * @returns {string} One of the values from the DEVICES object: 'ios', 'android', or 'desktop'.
 */
const actualDevice = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return iOS() ? DEVICES.ios : DEVICES.android
  } else {
    return DEVICES.desktop
  }
}

/**
 * Limits the length of a string to a specified number of characters, and appends a decorator if the string is truncated.
 * @param {string} string - The input string.
 * @param {number} length - The maximum length of the string.
 * @param {string} decorator - The decorator to append to the string if it's truncated (default: '...').
 * @returns {string} The input string, truncated to the specified length (if necessary) and decorated.
 */
const limitStringLength = (string = '', length, decorator = '...') => {
  if (string === null) return ''
  return string?.length > length ? string.substring(0, length) + `${decorator}` : string
}

/**
 * Crops a string to a specified number of characters.
 * @param {string|undefined|null} content - The input string.
 * @param {number|undefined|null} chars - The maximum length of the string to be returned.
 * @returns {string} The input string, cropped to the specified length (if necessary).
 */
const cropText = (content, chars) => {
  const text = String(content ?? '')
  const res = text.substring(0, chars ?? text.length)
  return res
}

/**
 * Returns a string containing the concatenated content of an FAQ item answer.
 * @param {object} itemContent - An object representing the FAQ item content, with a possible nested structure.
 * @returns {string} - A string containing the concatenated content of the answer, or an empty string if the input is invalid.
 */
const returnContentStringFaqAnswer = (itemContent) => {
  return itemContent?.content
    ? itemContent?.content
        ?.map((contentAsw) => returnContentStringFaqAnswer(contentAsw) || '')
        ?.join(' ')
    : itemContent?.value || ''
}

export {
  actualDevice,
  cropText,
  DEVICES,
  iOSSafariMobile,
  limitStringLength,
  returnContentStringFaqAnswer,
  scrollToTop,
  TYPES_SOURCES_VIDEOS,
}
