import Head from 'next/head'
import { getFeatureDisabledElements, getFeatureDisabledRoutes } from 'utils/next/routes'
import { LayoutProvider } from 'providers/LayoutProvider'
import { HeaderProvider } from 'providers/HeaderProvider'
import navData from 'components/Navigation/navigation.json'
import Header from 'components/Header'
import styles from 'components/Layout/Layout.module.scss'
import { useRouter } from 'next/router'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { Alchemer } from 'components/Alchemer'
import { som } from 'constants/schemaOrganizationMarkup'
import { useState } from 'react'
import { FFAMBanner } from 'components/FFAMBanner'
import { isFFAMDisabled } from 'constants/ffam'
import {
  getTwitterImage,
  getOgTypeMetaTag,
  getPaginatedPageCanonicalLink,
  getPageMetaTitleForRoute,
} from 'utils/metadata'
import FooterContainer from 'components/Footer/FooterContainer/FooterContainer'
import { useIsBlog } from 'utils/routes'
import { PRD_ENV } from 'constants/environments'

export default function Layout({
  canonicalLink,
  children,
  featureToggleObject,
  pageDisclosure = '',
  pageMetaData = [],
  metaDescription,
  pageMetaTitle,
  isFFAMReferrer,
  ffamReferrerContent,
  footer,
  secondaryDisclosures = [],
  pageTitle,
  publicProcessDotEnv,
}) {
  // Consider it a lower environment if we have an app env being passed and it's not set as
  // `production`. If it matches production or no env was passed, do not block bots
  const appEnv = publicProcessDotEnv?.NEXT_PUBLIC_APP_ENV
  const isLowerEnv = appEnv ? appEnv !== PRD_ENV : false

  const disabledRoutes = getFeatureDisabledRoutes(featureToggleObject)
  const disabledElements = getFeatureDisabledElements(featureToggleObject)

  const usesSEOMetaTags = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_SEO)
  const usesSiteWideBanner = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_SITE_WIDE_BANNER)

  const [closeFFAMBanner, setCloseFFAMBanner] = useState(false)

  const router = useRouter()
  // TODO: Fix this. The name and intention of useIsBlog is confusing and duplicates useRouter logic
  const route = useIsBlog()

  const hasUTM = router.asPath ? router.asPath.includes('utm_') : false

  const dynamicPageMetaTitle = getPageMetaTitleForRoute(router, pageMetaTitle, pageTitle)
  const image = getTwitterImage(route, children)

  const handleCloseDialog = () => {
    setCloseFFAMBanner(true)
  }

  canonicalLink = getPaginatedPageCanonicalLink(canonicalLink)
  return (
    <>
      <Head data-testid="layout-head">
        <title data-testid="layout-title">
          {dynamicPageMetaTitle
            ? `${dynamicPageMetaTitle} ${router.pathname === '/' ? '' : '| Achieve'}`
            : `Achieve`}
        </title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" data-testid="layout-head-canonical-link" href={`${canonicalLink}`} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta charSet="UTF-8" />
        {
          /* Add No follow for pages with utm parameters */
          (isLowerEnv || hasUTM) && <meta name="robots" content="noindex, nofollow" />
        }

        {usesSEOMetaTags && (
          <>
            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@achievecom" />
            <meta name="twitter:title" content={`${dynamicPageMetaTitle}`} />
            {metaDescription && <meta name="twitter:description" content={`${metaDescription}`} />}
            <meta name="twitter:image" content={image} />
            {/* Facebook */}
            <meta name="og:image" content={image} />
            <meta name="og:locale" content="United States" />
            <meta name="og:title" content={`${dynamicPageMetaTitle}`} />
            <meta name="og:url" content={`${canonicalLink}`} />
            <meta name="og:site_name" content="Achieve" />

            {getOgTypeMetaTag(route)}
            {metaDescription && <meta name="og:description" content={`${metaDescription}`} />}

            {/* Schema Organization Markup */}
            {router.pathname === '/' && (
              <script
                className="structured-data-list"
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: som }}
              />
            )}

            {/*Meta data description tag*/}
            {metaDescription && <meta name="description" content={metaDescription} />}

            {/*Meta data other tags*/}
            {pageMetaData
              .filter(({ fields: { name } }) => name !== 'viewport')
              .map(({ fields: { name, content } }) => (
                <meta key={name} name={name} content={content} />
              ))}
          </>
        )}
      </Head>

      <LayoutProvider>
        <HeaderProvider>
          <Header
            mainNavigation={navData.mainNavigation}
            disabledRoutes={disabledRoutes}
            disabledElements={disabledElements}
          />
        </HeaderProvider>
        <div className={styles['max-width-container']} data-narrow={true}>
          <main className={styles.main}>
            {!closeFFAMBanner && !isFFAMDisabled() && usesSiteWideBanner && (
              <FFAMBanner
                isFFAMReferrer={isFFAMReferrer}
                ffamReferrerContent={ffamReferrerContent}
                handleCloseDialog={handleCloseDialog}
              />
            )}
            {children}
          </main>
        </div>
        <FooterContainer
          pageDisclosure={pageDisclosure}
          disabledRoutes={disabledRoutes}
          content={footer}
          secondaryDisclosures={secondaryDisclosures}
        />
      </LayoutProvider>

      {/* Alchemer survey script */}
      {disabledElements[FEATURE_TOGGLES.ACX_WEB_ENABLE_ALCHEMER_SURVEY] && <Alchemer />}
    </>
  )
}
