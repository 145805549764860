import React, { useContext } from 'react'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { ANALYTICS } from 'constants/analytics'
import styles from './TrackingWrapper.module.scss'

function TrackingWrapper({ track, onClick, withNextLink, children, ...props }) {
  const { dispatch } = useContext(AnalyticsContext)

  const localClickHandler = (event, track, onClick, withNextLink) => {
    const getTrackingObject = (track) => {
      if (track.click_type) {
        return {
          ...track,
          click_url: `${track.href}`,
          click_text: `${ANALYTICS.APP_NAME} | ${track.click_id}`,
        }
      } else {
        return track
      }
    }
    if (withNextLink) {
      event.stopPropagation()
      event.preventDefault()
    }

    dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload: { event: handleTrackAndReactEvent(event, getTrackingObject(track), onClick) },
    })
  }

  return (
    <span
      className={styles['wrapper']}
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, onClick, withNextLink)
      }}
    >
      <> {children}</>
    </span>
  )
}

export { TrackingWrapper }
