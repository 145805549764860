import { useEffect, useContext } from 'react'
import { ANALYTICS } from 'constants/analytics'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'
import { AnalyticsContext } from 'providers/AnalyticsProvider'

const createSession = ({ dispatch }) => {
  // Session Storage persists until the tab/browser is closed.
  // Unlike session cookies which have been broken by browsers like Chrome.
  const sessionID = sessionStorage.getItem(ANALYTICS.ACHIEVE_SESSION_KEY_NAME)
  if (sessionID) return
  const newSessionID = uuidv4()
  sessionStorage.setItem(ANALYTICS.ACHIEVE_SESSION_KEY_NAME, newSessionID)
  // We use session storage's built in logic to control when we get a new session
  // Then for technical requirements from MP flow "/get-started" app, we set it as a cookie
  // Since the cookie will be set each time a new session is set, I just decided to make it
  // expire the same amount of time as the profile cookie. It's in days.
  Cookies.set(ANALYTICS.ACHIEVE_SESSION_KEY_NAME, newSessionID, {
    expires: 36525,
    sameSite: 'strict',
  })
  dispatch({
    type: 'SET_ACHIEVE_SESSION_ID',
    id: newSessionID,
  })
}

const useSession = () => {
  const { dispatch } = useContext(AnalyticsContext)
  return useEffect(() => {
    createSession({ dispatch })
  }, [dispatch])
}

export { createSession, useSession }
