import * as yup from 'yup'

/**
 * For usage see https://github.com/jquense/yup
 */

export const richTextSchema = yup.object({
  content: yup.array().of(yup.object()).required(),
  data: yup.object().required(),
  nodeType: yup.string().oneOf(['document']).required(),
})

export const imageFieldSchema = yup.object({
  fields: yup
    .object({
      file: yup
        .object({
          url: yup.string().required(),
        })
        .required(),
      title: yup.string().required(),
    })
    .required(),
})

export const carouselItemSchema = yup.array().of(
  yup.object({
    fields: yup
      .object({
        cardImage: yup.object().required(),
        cardLink: yup.object(),
        cardText: yup.object().required(),
        cardTitleText: yup.object(),
      })
      .required(),
  })
)

export const gritItemSchema = yup.array().of(
  yup.object({
    fields: yup
      .object({
        cardImage: yup.object().required(),
        cardLink: yup.object(),
        cardText: yup.object().required(),
        cardTitleText: yup.object(),
        cardTitleSubtitleText: yup.object(),
        fieldName: yup.string().required(),
      })
      .required(),
  })
)

export const faqSetSchema = yup.array().of(
  yup.object({
    fields: yup
      .object({
        answer: richTextSchema,
        question: richTextSchema,
      })
      .required(),
  })
)

export const ctaButtonSchema = yup.object({
  linkHref: yup.string().required(),
  linkText: richTextSchema,
})
